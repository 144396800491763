const IconLinkedin = ({ fill = 'currentColor', ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15.15 8.4C14.3836 8.39737 13.6242 8.54589 12.9152 8.83707C12.2062 9.12826 11.5616 9.55638 11.0182 10.0969C10.4749 10.6375 10.0434 11.2799 9.74861 11.9874C9.45379 12.6949 9.30136 13.4535 9.30005 14.22V20.1C9.30005 20.3387 9.39487 20.5676 9.56365 20.7364C9.73244 20.9052 9.96135 21 10.2 21H12.3C12.5387 21 12.7677 20.9052 12.9364 20.7364C13.1052 20.5676 13.2 20.3387 13.2 20.1V14.22C13.1999 13.9474 13.2571 13.6778 13.3681 13.4288C13.4791 13.1798 13.6413 12.957 13.8441 12.7749C14.047 12.5928 14.2859 12.4555 14.5454 12.372C14.8049 12.2884 15.0791 12.2605 15.35 12.29C15.8361 12.3512 16.2827 12.5887 16.6053 12.9573C16.9278 13.326 17.1039 13.8002 17.1 14.29V20.1C17.1 20.3387 17.1949 20.5676 17.3637 20.7364C17.5324 20.9052 17.7614 21 18 21H20.1C20.3387 21 20.5677 20.9052 20.7364 20.7364C20.9052 20.5676 21 20.3387 21 20.1V14.22C20.9987 13.4535 20.8463 12.6949 20.5515 11.9874C20.2567 11.2799 19.8252 10.6375 19.2819 10.0969C18.7385 9.55638 18.0939 9.12826 17.3849 8.83707C16.6759 8.54589 15.9165 8.39737 15.15 8.4Z'
      fill={fill}
    />
    <path
      d='M6.6 9.29999H3.9C3.40294 9.29999 3 9.70293 3 10.2V20.1C3 20.597 3.40294 21 3.9 21H6.6C7.09706 21 7.5 20.597 7.5 20.1V10.2C7.5 9.70293 7.09706 9.29999 6.6 9.29999Z'
      fill={fill}
    />
    <path
      d='M5.25 7.5C6.49264 7.5 7.5 6.49264 7.5 5.25C7.5 4.00736 6.49264 3 5.25 3C4.00736 3 3 4.00736 3 5.25C3 6.49264 4.00736 7.5 5.25 7.5Z'
      fill={fill}
    />
  </svg>
);

export default IconLinkedin;
